<template>
    <div class="pt-5">
        <h1 class="flex-auto text-lg lg:text-xl font-semibold">
            Siap berbuat baik hari ini!
        </h1>
        <div v-if="categories.length > 0">
            <div class="mt-5 grid grid-cols-4 gap-1 md:gap-4 text-center items-center">
                <div v-for="category in categories" :key="category.name" class="lg:col-span-1 bg-white rounded-xl shadow-md p-2 text-center text-xs">
                    <!-- <router-link :to="{name: 'category.show', params:{slug: category.slug}}"> -->
                        
                    <button @click="setCampaigns(category.slug)">
                        <img :src="category.image" width="50" class="inline-block mb-2 h-10 object-contain">
                        <div v-if="category.slug == store.state.category.selectedCategoryHome">
                            <div class="font-semibold text-xs title underline text-yellow-500">{{ category.name }}</div>
                        </div>
                        <div v-else>
                            <div class="font-medium text-xs title">{{ category.name }}</div>
                        </div>
                    </button>

                    <!-- </router-link> -->
                </div>

                <!--
                <div class="lg:col-span-1 bg-white rounded-xl shadow-md p-2 text-center text-xs">
                    <router-link :to="{name: 'category.index'}">
                        <div>
                            <img src="@/assets/images/menu.png" width="50" class="inline-block mb-2 p-1 h-10 object-contain">
                        </div>
                        <div class="font-medium text-xs title">Lainnya</div>
                    </router-link>
                </div>
                -->
            </div>
            <div v-if="categoryCampaigns.length > 0">
                <div class="mt-5 grid grid-cols-4 gap-4" v-for="campaign in categoryCampaigns" :key="campaign.id">
                    <div class="col-span-4">
                        <CampaignLargeItem 
                            :image="campaign.image" 
                            :title="campaign.title" 
                            :slug="campaign.slug" 
                            :sumDonation="campaign.sum_donation"
                            :targetDonation="campaign.target_donation"
                            :maxDate="campaign.max_date" 
                            :typeCampaign="campaign.tipe_campaign" /> 
                    </div>
                </div>
                <router-link :to="{name: 'category.show', params:{slug: store.state.category.selectedCategoryHome}}">
                    <h1 class="mt-3 px-3 text-base text-center underline font-semibold text-yellow-500">
                        Lihat Semua
                    </h1>
                </router-link>
            </div>
            <div v-else>
                <div class="text-center">...</div>
            </div>
        </div>
        <div v-else>
            <ContentLoader 
                :speed="2"
                viewBox="0 0 600 150"
                primaryColor="#f3f3f3"
                secondaryColor="#ECEBEB"
            >
                <rect x="0" y="23" rx="12" ry="12" width="138" height="100" /> 
                <rect x="154" y="23" rx="12" ry="12" width="138" height="100" /> 
                <rect x="308" y="23" rx="12" ry="12" width="138" height="100" /> 
                <rect x="462" y="23" rx="12" ry="12" width="138" height="100" />
            </ContentLoader>
        </div>
    </div>
</template>

<script>
    //hook vue
    import { computed, onMounted } from 'vue'
    
    //vuex
    import { useStore } from 'vuex'
    
    //vue content loader
    import { ContentLoader } from 'vue-content-loader'

    import CampaignLargeItem from '@/components/CampaignLargeItem.vue'

    export default {

        components: {
            ContentLoader,   // <-- register content loader
            CampaignLargeItem
        },

        setup() {

            //store vuex
            const store = useStore()

            //onMounted akan menjalankan action "getCategoryHome" di module "category"
            onMounted(() => {
                setCampaigns()
            })

            function setCampaigns(slug = '') {
                store.dispatch('category/getCategoryHome',  {
                    store,
                    slug
                })
                console.log('test 123', slug)
            }

            //digunakan untuk get data state "categories" di module "category" 
            const categories = computed(() => {
                var data = store.state.category.categories
                return data.slice(0, 4).reverse()
            })

            const categoryCampaigns = computed(() => {
                var data = store.state.category.campaignCategory

                if (data.length < 5) {
                    return data
                } else {
                    return data.slice(0, 3)
                }
            })

            return {
                store,
                categories,  // <-- categories
                setCampaigns,
                categoryCampaigns
            }

        }

    }
</script>

<style>

.title{
    word-break: break-word;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    line-height: 16px; /* fallback */
    max-height: 32px; /* fallback */
    -webkit-line-clamp: 1; /* number of lines to show */
    -webkit-box-orient: vertical;
}

</style>