<template>
    <div>
        <div v-if="sliders.length > 0">
            <vueper-slides autoplay :bullets="false" :dragging-distance="70">
                <template #arrow-left>
                    <div class="ml-5">
                        <i class="fa fa-chevron-left fa-lg text-yellow-600" />
                    </div>
                </template>
                <template #arrow-right>
                    <div class="mr-5">
                        <i class="fa fa-chevron-right fa-lg text-yellow-600" />
                    </div>
                </template>
                                
                <vueper-slide v-for="(slider, i) in sliders" :key="i">
                    <template #content>
                        <a :href="slider.link">
                            <img :src="slider.image"/>
                        </a>
                    </template>
                </vueper-slide>
            </vueper-slides>
        </div>
        <div v-else>
            <ContentLoader />
        </div>
    </div>
</template>

<script>
    //hook vue
    import { computed, onMounted  } from 'vue'

    //vuex
    import { useStore } from 'vuex'

    //content loader
    import { ContentLoader } from 'vue-content-loader'
    
    //vueper slider
    import { VueperSlides, VueperSlide } from 'vueperslides'
    import 'vueperslides/dist/vueperslides.css'

    export default {

        components: {
            ContentLoader,
            VueperSlides, 
            VueperSlide
        },

        setup() {

            //store vuex
            const store = useStore()

            //onMounted akan menjalankan action "getSlider" di module "slider"
            onMounted(() => {
                store.dispatch('slider/getSlider')
            })

            //digunakan untuk get data state "sliders" di module "slider" 
            const sliders = computed(() => {
                return store.state.slider.sliders
            })

            return {
                sliders,   // <-- sliders
            }

        }

    }
</script>

<style scoped>
    .vueperslide__image {
        transform: rotate(-10deg);
    }

    .vueperslide__title {
        font-size: 7em;
        opacity: 0.7;
    }

    .vueperslides--fixed-height { height: 168px; }

    @media (min-width: 1024px) {
        .vueperslides--fixed-height { height: 450px; }
    }
</style>