<template>
    <router-link :to="{name: 'campaign.show', params:{slug: slug}}">
        <div class="bg-white rounded-xl shadow-lg">
            <div class="md:flex rounded-xl md:p-0">
                <img class="w-full h-36 md:w-56 rounded-t-xl lg:rounded-tr-none lg:rounded-bl-xl object-cover"
                    :src="image">
                <div class="w-full pt-2 md:pt-5 p-4 md:p-3 text-left space-y-4">
                    <p class="text-sm font-semibold title">{{ title }}</p>
                    <div class="font-medium">
                        <div v-if="sumDonation.length > 0">
                            <div v-for="donation in sumDonation" :key="donation">
                                <div
                                    v-if="typeCampaign == campaignType.QURBAN_KAMBING || typeCampaign == campaignType.QURBAN_SAPI || typeCampaign == campaignType.QURBAN_PATUNGAN">
                                    <p class="text-xs text-gray-500 lg:mb-12">
                                        <span class="font-bold text-yellow-600">Rp. {{ formatPrice(donation.total) }}
                                        </span> terkumpul
                                    </p>
                                </div>
                                <div v-else>
                                    <div class="relative">
                                        <div class="overflow-hidden h-2 mb-1 text-xs flex rounded bg-yellow-200">
                                            <div :style="{width: percentage(donation.total, targetDonation) + '%'}"
                                                class="shadow-none flex flex-col text-center whitespace-nowrap text-white justify-center bg-yellow-500">
                                            </div>
                                        </div>
                                    </div>

                                    <p class="text-xs text-gray-500">
                                        <span class="font-bold text-yellow-600">Rp. {{ nFormatter(donation.total, 1) }}
                                        </span> terkumpul dari
                                        <span class="font-bold">Rp. {{ nFormatter(targetDonation, 1) }}</span>
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div
                            v-else-if="typeCampaign == campaignType.QURBAN_KAMBING || typeCampaign == campaignType.QURBAN_SAPI || typeCampaign == campaignType.QURBAN_PATUNGAN">

                            <p class="text-xs text-gray-500 lg:mb-12">
                                <span class="font-bold text-yellow-500">Rp. 0 </span> terkumpul
                            </p>
                        </div>
                        <div v-else>

                            <div class="relative">
                                <div class="overflow-hidden h-2 mb-1 text-xs flex rounded bg-yellow-200">
                                    <div :style="{width: percentage(0, targetDonation) + '%'}"
                                        class="shadow-none flex flex-col text-center whitespace-nowrap text-white justify-center bg-yellow-500">
                                    </div>
                                </div>
                            </div>

                            <p class="text-xs text-gray-500">
                                <span class="font-bold text-yellow-500">Rp. 0 </span> terkumpul dari
                                <span class="font-bold">Rp. {{ nFormatter(targetDonation, 1) }}</span>
                            </p>
                        </div>
                        <div class="mt-3 text-xs">
                            <strong>{{ countDay(maxDate) }}</strong> hari lagi
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </router-link>
</template>

<script>

    import CampaignTypeEnum from '@/enums/CampaignTypeEnum.js'

    export default {
        props: {
            image: String,
            title: String,
            slug: String,
            sumDonation: Array,
            targetDonation: String,
            maxDate: String,
            typeCampaign: String
        },
        setup() {
            const campaignType = CampaignTypeEnum

            return {
                campaignType
            }
        }
    }
</script>

<style>
    .title {
        word-break: break-word;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        line-height: 16px;
        /* fallback */
        max-height: 32px;
        /* fallback */
        -webkit-line-clamp: 1;
        /* number of lines to show */
        -webkit-box-orient: vertical;
    }
</style>